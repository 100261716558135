<template>
  <CRow class="justify-content-center m-0 mt-3">
    <CCol sm="12">
      <CTabs variant="tabs" :active-tab="0">
        <CTab :title="`BL`">
          <CCard class="card-border pt-3">
            <CRow class="m-0">
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-auto required col-form-label-sm mb-0">{{`${$t('label.totalWeight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.dataBasic.Weight.$dirty ? 'form-control' : (vForm.dataBasic.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="TotalBl.Weight"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.dataBasic.Weight.$error">
                      {{ errorMessage(vForm.dataBasic.Weight) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-auto col-form-label-sm mb-0">{{`${$t('label.TotalVolume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.dataBasic.Volumen.$dirty ? 'form-control' : (vForm.dataBasic.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="TotalBl.Volumen"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.dataBasic.Volumen.$error">
                      {{ errorMessage(vForm.dataBasic.Volumen) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-6 required col-form-label-sm mb-0">{{`${$t('label.totalQuantity')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-6 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!vForm.dataBasic.Quantity.$dirty ? 'form-control' : (vForm.dataBasic.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="TotalBl.Quantity"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.dataBasic.Quantity.$error">
                      {{ errorMessage(vForm.dataBasic.Quantity) }}
                    </div>
                  </div>
                </div>
              </CCol>
            </CRow>
          </CCard>   
        </CTab>
      </CTabs>
    </CCol>

    <CCol sm="12">
      <CTabs :active-tab="TabIndex" @update:activeTab="TabIndex=$event" variant="tabs" class="m-0">
        <CTab :title="$t('label.generalInfo')" :class="vForm.Packaging.PackagingId.$error || vForm.Packaging.Quantity.$error || vForm.Packaging.Weight.$error ? 'tab-error' : ''">
          <CCard class="card-border pt-3 m-0"> 
            <CRow class="m-0">
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-1">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.PackagingOrigin')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  :options="PackagingOriginOptions"
                  addLabelClasses="required text-right"
                  v-model="vForm.Packaging.PackagingOrigin.$model"
                  :is-valid="hasError(vForm.Packaging.PackagingOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.PackagingOrigin)"
                  @change="onChangePackagingOrigin($event.target.value)"
                />
              </CCol>
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-1">
                <CSelect
                  v-if="SerialJson.length != 0"
                  size="sm"
                  v-uppercase
                  :label="$t('label.SerialOrigin')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  :options="SerialOriginOptions"
                  :addLabelClasses="form.Packaging.FgSerialOrigin ? 'required text-right' : 'text-right'"
                  v-model="vForm.Packaging.SerialOrigin.$model"
                  :is-valid="hasError(vForm.Packaging.SerialOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.SerialOrigin)"
                  @change="onChangeSerialOrigin($event.target.value)"
                />
                <CInput
                  v-else
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.SerialOrigin')"
                  addLabelClasses="text-right"
                  :label="$t('label.SerialOrigin')"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                  value="N/A"
                  :is-valid="hasError(vForm.Packaging.SerialOrigin)"
                  :invalid-feedback="errorMessage(vForm.Packaging.SerialOrigin)"
                  disabled
                />
              </CCol>
              <CCol v-if="validCargoMaster" sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 required col-form-label-sm">{{`${$t('label.WeightOrigin')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.WeightOrigin.$dirty ? 'form-control' : (vForm.Packaging.WeightOrigin.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.WeightOrigin.$model"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.WeightOrigin.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.WeightOrigin) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol  sm="12" lg="4" class="px-1">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.commodity')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  :options="CommodityOptions"
                  addLabelClasses="text-right"
                  v-model="vForm.Packaging.CommodityId.$model"
                  :is-valid="hasError(vForm.Packaging.CommodityId)"
                  :invalid-feedback="errorMessage(vForm.Packaging.CommodityId)"
                  @change="onChangeCommodityId"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <CInput
                  v-uppercase
                  size="sm"
                  placeholder="RUBRO"
                  addLabelClasses="text-right"
                  label="RUBRO"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                  v-model="vForm.Packaging.HeadingName.$model"
                  :is-valid="hasError(vForm.Packaging.HeadingName)"
                  :invalid-feedback="errorMessage(vForm.Packaging.HeadingName)"
                  disabled
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <CInput
                  v-uppercase
                  size="sm"
                  :placeholder="$t('label.serial')"
                  addLabelClasses="text-right"
                  :label="$t('label.serial')"
                  :horizontal="{label: 'col-sm-12 col-lg-5', input: 'col-sm-12 col-lg-7'}"
                  v-model="vForm.Packaging.Serial.$model"
                  :is-valid="hasError(vForm.Packaging.Serial)"
                  :invalid-feedback="errorMessage(vForm.Packaging.Serial)"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 required col-form-label-sm">{{`${$t('label.quantity')}`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="QuantityMeasure" 
                      :class="!vForm.Packaging.Quantity.$dirty ? 'form-control' : (vForm.Packaging.Quantity.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Quantity.$model"
                      maxlength= "10"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Quantity.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Quantity) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 required col-form-label-sm">{{`${$t('label.weight')} (KGM)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Weight.$dirty ? 'form-control' : (vForm.Packaging.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Weight.$model"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Weight.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Weight) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-5 m-0 text-right">
                    {{$t('label.ApplyPackaging')}}
                  </label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="form.Packaging.FgPackaging"
                      @update:checked="vForm.Packaging.PackagingId.$model = '', FgExternPackaging = false"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1" v-if="form.Packaging.FgPackaging">
                <CSelect
                  size="sm"
                  v-uppercase
                  :label="$t('label.packaging')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  :options="PackagingOptions"
                  addLabelClasses="required text-right"
                  v-model="vForm.Packaging.PackagingId.$model"
                  :is-valid="hasError(vForm.Packaging.PackagingId)"
                  :invalid-feedback="errorMessage(vForm.Packaging.PackagingId)"
                  @change="onChangePackagingId($event.target.value)"
                />
              </CCol>
              <CCol sm="12" lg="4" class="px-1" v-if="form.Packaging.FgPackaging">
                <div class="d-flex justify-content-end">
                  <div class="col-sm-auto col-lg-12 d-flex p-0">
                    <label class="col-form-label-sm col-sm-auto col-lg-5 text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.internal')}}
                    </label>
                    <div class="col-sm-auto d-flex justify-content-end" style="padding-right: 5px; padding-left: 5px;">
                      <CSwitch
                        color="watch"
                        variant="3d"
                        type="checkbox"
                        :checked.sync="FgExternPackaging"
                      />
                    </div>
                    <label class="col-form-label-sm col-sm-auto text-right" style="padding-right: 5px; padding-left: 5px;">
                      {{$t('label.external')}}
                    </label>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1" v-if="form.Packaging.FgPackaging">
                <div class="form-group form-row" rol="group">
                  <label class="col-form-label-sm col-sm-12 col-lg-5 m-0 text-right">
                    {{$t('label.ApplySerializatiom')}}
                  </label>
                  <div class="col-sm-12 col-lg-auto d-flex justify-content-end">
                    <CSwitch
                      color="watch"
                      variant="3d"
                      type="checkbox"
                      :checked.sync="form.Packaging.FgRequiredSerialization"
                      :disabled="form.Packaging.PackagingId ? !PackagingData?.FgApplySerialization : true"
                    />
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <CTextarea
                  :label="$t('label.description')"
                  :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
                  size="sm"
                  v-uppercase
                  v-model.trim="vForm.Packaging.DescriptionOfGoods.$model"
                  addLabelClasses="text-right"
                  rows="2"
                  :is-valid="hasError(vForm.Packaging.DescriptionOfGoods)"
                  :invalid-feedback="errorMessage(vForm.Packaging.DescriptionOfGoods)"
                />
              </CCol>
            </CRow>
            
          </CCard>
        </CTab>
        <CTab :title="$t('label.dimensions')" :class="vForm.Packaging.Length.$error || vForm.Packaging.Width.$error || vForm.Packaging.Height.$error ? 'tab-error' : ''">
          <CCard class="card-border pt-3 m-0">
            <CRow class="m-0">
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Length.$dirty ? 'form-control' : (vForm.Packaging.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Length.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Length.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Length) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Width.$dirty ? 'form-control' : (vForm.Packaging.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Width.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Width.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Width) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure" 
                      :class="!vForm.Packaging.Height.$dirty ? 'form-control' : (vForm.Packaging.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                      v-model="vForm.Packaging.Height.$model"
                      addLabelClasses="text-right"
                      maxlength= "13"
                    >
                    </money>
                    <div class="invalid-feedback" v-if="vForm.Packaging.Height.$error&&!CleanInformation">
                      {{ errorMessage(vForm.Packaging.Height) }}
                    </div>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 col-form-label-sm">{{`M²`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!vForm.Packaging.Length.$dirty || !vForm.Packaging.Width.$dirty || !vForm.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.SquareMeters"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
              <CCol sm="12" lg="4" class="px-1">
                <div class="form-group form-row">
                  <label class="text-right col-sm-12 col-lg-5 m-0 col-form-label-sm">{{`${$t('label.volume')} (M³)`}}</label>
                  <div class="input-group col-sm-12 col-lg-7 input-group-sm">
                    <money
                      size="sm"
                      v-bind="measure"
                      :class="!vForm.Packaging.Length.$dirty || !vForm.Packaging.Width.$dirty || !vForm.Packaging.Height.$dirty ? 'form-control' : 'form-control is-valid'"
                      v-model="Meters.Volumen"
                      addLabelClasses="required text-right"
                      maxlength= "13"
                      disabled
                    >
                    </money>
                  </div>
                </div>
              </CCol>
            </CRow>
            
          </CCard>
        </CTab>
      </CTabs>
      <CCol sm="12 px-0">
        <div class="col-sm-12 col-form-label-sm text-right px-0" style="text-align: left;">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="SubmitPackaging()"
          >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            @click="Clear(false)"
          >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CCol>
        
    
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListPackaging"
        :fields="packagingfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #Status="{ item }">
          <td class="text-center">
            <CBadge :color="getBadge(item.Status)">
              {{ $t('label.'+item.Status) }}
            </CBadge>
          </td>
        </template>
        <template #options="{ item }">
          <td class="text-center">
            <CButton 
              color="edit"
              square
              size="sm"
              class="mr-1"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.packaging'),
                placement: 'top-end'
              }"
              @click="EditPackaging(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.packaging'),
                placement: 'top-end',
              }"
              @click="DeletePackaging(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
      <div class="text-invalid-feedback text-center" v-if="vForm.PackagingList.$error">
        {{ $t('validation.RequiredDetail') }}
      </div>
    </CCol>
  </CRow>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';
import { Money } from "v-money";
import { NumberFormater } from "@/_helpers/funciones";

function data() {
  return {
    TabIndex: 0,
    FgExternPackaging: false,
    CleanInformation: false,
    isSubmit: false,
    PackagingSelectList: [],
    PackagingData: {},
    measure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 2,
      masked: false,
      maxlength: 18,
    },
    QuantityMeasure: {
      decimal: ",",
      thousands: ".",
      prefix: "",
      suffix: "",
      precision: 0,
      masked: false,
      maxlength: 6,
    },
    DetailJson: [],
    SerialJson: [],
  };
}

//methods
function getService() {
  this.$store.state.yardManagement.loading = true;
  let peticiones = [
    this.$http.ejecutar("GET", "Packaging-list", { Filter: 'ACTIVO' }),
  ];
  if (this.validCargoMaster) {
    peticiones.push(this.$http.ejecutar("GET", "YardWBlCargoPackagingInYard-list", { BlCargoId: this.form.dataBasic.BlCargoMasterId }))
  }
  Promise.all(peticiones)
    .then((responses) => {
      this.PackagingSelectList = responses[0].data.data ?? [];
      if (this.validCargoMaster) {
        this.DetailJson = responses[1].data.data ?? [];
      }
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

async function SubmitPackaging() {
  try {
    this.isSubmit = true;
    this.vForm.Packaging.$touch();
    if (this.vForm.Packaging.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.form.Packaging.Id=='' && this.form.PackagingList.some(item => item.PackagingId == this.form.Packaging.PackagingId)) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    if (this.form.Packaging.Id && this.PackagingData?.FgApplySerialization && this.form.SerializationList.length != 0) {
      let accumulator = { Weight: 0, Volumen: 0, Quantity: 0 };
      this.form.SerializationList.map(item => {
        if (item?.Status != 0 && item.PackagingId == this.form.Packaging.PackagingId) {
          accumulator.Weight += item.Weight;
          accumulator.Volumen += item.Volumen;
          accumulator.Quantity += 1;
        }
      })
      if (NumberFormater.setNum(this.form.Packaging.Quantity) < accumulator.Quantity) {
        throw this.$t('label.NotSerializedQuantity');
      }
      if (NumberFormater.setNum(this.form.Packaging.Weight) < accumulator.Weight) {
        throw this.$t('label.NotSerializedWeight');
      }
      if (NumberFormater.setNum(this.form.Packaging.Volumen) < accumulator.Volumen) {
        throw this.$t('label.NotSerializedVolume');
      }
    }

    let PackagingParentData = {};
    if (this.validCargoMaster) {
      PackagingParentData = this.DetailJson.find(e => e.BlCargoMasterDetailId == this.form.Packaging.PackagingOrigin);
    }

    if (this.form.Packaging.Id=='') {
      this.form.PackagingList.push({
        IdX: this.form.PackagingList.length > 0 ? this.form.PackagingList[this.form.PackagingList.length - 1].IdX + 1 : 1,
        BlCargoMasterDetailParentId: this.validCargoMaster ? this.form.Packaging.PackagingOrigin : '',
        BlCargoMasterDetailSerialParentId: this.validCargoMaster ? this.form.Packaging.SerialOrigin : '',
        PackagingParentNameEn: PackagingParentData?.PackagingNameEn ?? '',
        PackagingParentNameEs: PackagingParentData?.PackagingNameEs ?? '',
        WeigthParent: this.validCargoMaster ? NumberFormater.setNum(this.form.Packaging.WeightOrigin) : 0,
        PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
        HeadingId: this.form.Packaging.HeadingId ?? '',
        HeadingName: this.form.Packaging.HeadingName ?? '',
        CommodityId: this.form.Packaging.CommodityId ?? '',
        CommodityName: this.form.Packaging.CommodityName ?? '',
        Serial: this.form.Packaging.Serial ?? '',
        DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',  
        Length: NumberFormater.setNum(this.form.Packaging.Length),
        Width: NumberFormater.setNum(this.form.Packaging.Width),
        Height: NumberFormater.setNum(this.form.Packaging.Height),
        SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
        Weight: NumberFormater.setNum(this.form.Packaging.Weight),
        Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
        Volumen: NumberFormater.setNum(this.Meters.Volumen),
        PackagingNameEn: this.PackagingData?.PackagingNameEn ?? '',
        PackagingNameEs: this.PackagingData?.PackagingNameEs ?? '',
        FgExternPackaging: this.FgExternPackaging,
        FgApplySerialization: this.PackagingData?.FgApplySerialization ? true : false,
        FgRequiredSerialization: this.form.Packaging?.FgRequiredSerialization ? true : false,
        Status: 1,
      });
    }else{
      this.form.PackagingList = this.form.PackagingList.map(item => {
        if (item.IdX == this.form.Packaging.Id) {
          return {
            IdX: item.IdX,
            BlCargoMasterDetailId: item.BlCargoMasterDetailId,
            BlCargoMasterDetailParentId: this.validCargoMaster ? this.form.Packaging.PackagingOrigin : '',
            BlCargoMasterDetailSerialParentId: this.validCargoMaster ? this.form.Packaging.SerialOrigin : '',
            PackagingParentNameEn: PackagingParentData?.PackagingNameEn ?? '',
            PackagingParentNameEs: PackagingParentData?.PackagingNameEs ?? '',
            WeigthParent: this.validCargoMaster ? NumberFormater.setNum(this.form.Packaging.WeightOrigin) : 0,
            HeadingId: this.form.Packaging.HeadingId ?? '',
            HeadingName: this.form.Packaging.HeadingName ?? '',
            CommodityId: this.form.Packaging.CommodityId ?? '',
            CommodityName: this.form.Packaging.CommodityName ?? '',
            Serial: this.form.Packaging.Serial ?? '',
            DescriptionOfGoods: this.form.Packaging.DescriptionOfGoods ?? '',
            Length: NumberFormater.setNum(this.form.Packaging.Length),
            Width: NumberFormater.setNum(this.form.Packaging.Width),
            Height: NumberFormater.setNum(this.form.Packaging.Height),
            SquareMeters: NumberFormater.setNum(this.Meters.SquareMeters),
            PackagingId: this.form.Packaging.FgPackaging ? this.form.Packaging.PackagingId : process.env.VUE_APP_PACKAGING_ID_UNIDENTIFIED,
            Weight: NumberFormater.setNum(this.form.Packaging.Weight), 
            Quantity: NumberFormater.setNum(this.form.Packaging.Quantity),
            Volumen: NumberFormater.setNum(this.Meters.Volumen),
            PackagingNameEn: this.PackagingData?.PackagingNameEn ?? '',
            PackagingNameEs: this.PackagingData?.PackagingNameEs ?? '',
            FgExternPackaging: this.FgExternPackaging,
            FgApplySerialization: this.PackagingData?.FgApplySerialization ? true : false,
            FgRequiredSerialization: this.form.Packaging?.FgRequiredSerialization ? true : false,
            Status: 1,
          }
        }else{
          return item;
        }
      });
    }
    await this.Clear(false);
    this.isSubmit = false;
    this.vForm.Packaging.$reset();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

async function EditPackaging(item) {
  this.form.Packaging = {
    Id: item.IdX,
    PackagingOrigin: item?.BlCargoMasterDetailParentId ?? '',
    SerialOrigin: item?.BlCargoMasterDetailSerialParentId ?? '',
    WeightOrigin: item?.WeigthParent ?? 0,
    PackagingId: item.PackagingId ?? '',
    PackagingNameEn: item.PackagingNameEn,
    PackagingNameEs: item.PackagingNameEs,
    HeadingId: item.HeadingId ?? '',
    HeadingName: item.HeadingName ?? '',
    CommodityId: item.CommodityId ?? '',
    CommodityName: item.CommodityName ?? '',
    Serial: item.Serial ?? '',
    DescriptionOfGoods: item.DescriptionOfGoods ?? '',
    Length: item.Length ?? 0,
    Width: item.Width ?? 0,
    Height: item.Height ?? 0,
    Weight: item.Weight ?? 0, 
    Quantity: item.Quantity,
    FgPackaging: item.PackagingId ? true : false,
    FgRequiredSerialization: item.FgRequiredSerialization ? true : false,
    FgSerialOrigin: false,
  };
  this.FgExternPackaging = item.FgExternPackaging ? true : false;
  await this.onChangePackagingId(this.form.Packaging.PackagingId);
  if (this.validCargoMaster) {
    await this.onChangePackagingOrigin(this.form.Packaging.PackagingOrigin);
    if (this.SerialJson.length != 0) {
      await this.onChangeSerialOrigin(item.BlCargoMasterDetailSerialParentId);
    }
  }
  this.vForm.Packaging.$touch();
}

function DeletePackaging(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.FgApplySerialization && this.form.SerializationList.some(serialization => serialization.Packaging == item.Packaging)) {
          this.notifyError({text: this.$t('label.PackagingIsSerialized')});
        }else{
          if (item.BlCargoMasterDetailId) {
            this.form.PackagingList = this.form.PackagingList.map(Packaging => {
              if (Packaging.BlCargoMasterDetailId == item.BlCargoMasterDetailId) {
                return {
                  ...Packaging,
                  Status: 0,
                };
              } else {
                return Packaging;
              }
            });
          }else{
            this.form.PackagingList = this.form.PackagingList.filter(e => e.IdX != item.IdX);
          }
          this.vForm.PackagingList.$touch();
        }
      }
    });
}

function onChangePackagingOrigin(id) {
  this.form.Packaging.PackagingOrigin = id;
  this.form.Packaging.SerialOrigin = '';
  if (this.form.Packaging.PackagingOrigin) {
    let PackagingOriginData = this.DetailJson.find(e => e.BlCargoMasterDetailId == this.form.Packaging.PackagingOrigin);
    this.SerialJson = PackagingOriginData?.SerialJson ?? [];
    this.form.Packaging.FgSerialOrigin = this.SerialJson.length != 0;
    this.form.Packaging.WeightOrigin = this.SerialJson.length != 0 ? 0 : (PackagingOriginData?.Weigth ?? 0);
  }else{
    this.form.WeightOrigin = 0;
    this.SerialJson = [];
    this.form.Packaging.FgSerialOrigin = false;
  }
}

function onChangeSerialOrigin(id) {
  this.form.Packaging.SerialOrigin = id;
  this.form.Packaging.WeightOrigin = 0
  if (this.form.Packaging.SerialOrigin) {
    let SerialJsonData = this.SerialJson.find(e => e.BlCargoMasterDetailSerialId == this.form.Packaging.SerialOrigin);
    this.form.Packaging.WeightOrigin = SerialJsonData?.Weigth ?? 0;
  }
}

function onChangeCommodityId(event) {
  this.form.Packaging.CommodityId = event.target.value;
  if (this.form.Packaging.CommodityId) {
    let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let CommodityData = this.CommodityList.find(item => item.CommodityId == this.form.Packaging.CommodityId);
    this.form.Packaging.HeadingName = CommodityData?.[`HeadingName${_lang}`] ?? '';
    this.form.Packaging.HeadingId = CommodityData?.HeadingId ?? '';
    this.form.Packaging.CommodityName = CommodityData?.[`CommodityName${_lang}`] ?? '';
  }else{
    this.form.Packaging.HeadingId = '';
    this.form.Packaging.HeadingName = '',
    this.form.Packaging.CommodityName = '';
  }
}

function onChangePackagingId(id) {
  this.form.Packaging.PackagingId = id;
  if (this.form.Packaging.PackagingId) {
    this.PackagingData = this.PackagingSelectList.find(item => item.PackagingId == this.form.Packaging.PackagingId) ?? {};
  }else{
    this.PackagingData = {};
  }
}

function Clear(All) {
  this.CleanInformation = true;
  this.form.Packaging = {
    Id: '',
    PackagingOrigin: '',
    SerialOrigin: '',
    WeightOrigin: 0,
    PackagingId: '',
    PackagingNameEn: '',
    PackagingNameEs: '',
    HeadingId: '',
    HeadingName: '',
    CommodityId: '',
    CommodityName: '',
    Serial: '',
    DescriptionOfGoods: '',
    Length: 0,
    Width: 0,
    Height: 0,
    Weight: 0, 
    Quantity: 0,
    Volumen: 0,
    FgPackaging: false,
    FgRequiredSerialization: false,
    FgSerialOrigin: false,
  };
  this.FgExternPackaging = false;
  this.PackagingData = {};
  this.SerialJson = [];
  if (All) {
    this.form.PackagingList = [];
    this.PackagingSelectList = [];
  }
  setTimeout(() => {
    this.vForm.Packaging.$reset();
    this.CleanInformation = false;
  }, 10);
}

//computed
function PackagingOriginOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.DetailJson.map(function(e){
    chart.push({
      value: e.BlCargoMasterDetailId,
      label: e['PackagingName'+_lang],
    })    
  })
  return chart;
}

function SerialOriginOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.SerialJson.map(function(e){
    chart.push({
      value: e.BlCargoMasterDetailSerialId,
      label: e.Serial,
    })    
  })
  return chart;
}

function PackagingOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingSelectList.filter(item => this.validCargoMaster ? item.PackagingId != process.env.VUE_APP_PACKAGING_ID_CONTAINER : true).forEach((e) => {
    if (!this.form.PackagingList.some(List => List.PackagingId == e.PackagingId && this.form.Packaging.Id != List.IdX)) {
      chart.push({
        value: e.PackagingId,
        label: e['PackagingName'+_lang],
      })   
    }
  })
  return chart;
}

function CommodityOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.CommodityList.map(function(e){
    chart.push({
      value: e.CommodityId,
      label: e['CommodityName'+_lang],
    })    
  })
  return chart;
}

function TotalBl() {
  let Total = {
    Weight: 0,
    Volumen: 0,
    Quantity: 0,
  };
  this.form.PackagingList.filter(item => item.Status == 1).forEach(item => {
    Total.Weight += item.Weight;
    Total.Volumen += item.Volumen;
    Total.Quantity += item.Quantity;
  })
  this.form.dataBasic.Weight = Total.Weight;
  this.form.dataBasic.Volumen = Total.Volumen;
  this.form.dataBasic.Quantity = Total.Quantity;

  return Total;
}

function Meters() {
  return {
    SquareMeters: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width),
    Volumen: NumberFormater.setNum(this.form.Packaging.Length) * NumberFormater.setNum(this.form.Packaging.Width) * NumberFormater.setNum(this.form.Packaging.Height),
  }
}

function computedListPackaging() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = this.form.PackagingList.filter(item => item.Status != 0);
  return List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      PackagingOriginName: item['PackagingParentName'+_lang],
      ComputedSerialOrigin: item.SerialParent ?? 'N/A',
      ComputedWeightOrigin: NumberFormater.formatNumber(item.WeigthParent, 2),
      PackagingName: item['PackagingName'+_lang] ?? this.$t('label.BreakBulk'),
      ComputedSerial: item?.Serial ? item?.Serial : 'N/A',
      Quantity: NumberFormater.formatNumber(item.Quantity, 2),
      ComputedWeight: NumberFormater.formatNumber(item.Weight, 2),
      ComputedVolume: NumberFormater.formatNumber(item.Volumen, 2),
      ComputedSquareMeters: NumberFormater.formatNumber(item.SquareMeters, 2),
    }
  });
}

function packagingfields(){
  if (this.validCargoMaster) {
    return [
      { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _style: 'min-width: 45px; text-align:left', _classes: 'text-center', filter: false },
      { key: 'PackagingOriginName', label: this.$t('label.PackagingOrigin'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;', sorter: true, filter: true},
      { key: 'ComputedSerialOrigin', label: this.$t('label.SerialOrigin'),  _classes: 'text-uppercase text-center', _style: 'min-width: 160px', sorter: true, filter: true},
      { key: 'ComputedWeightOrigin', label: `${this.$t('label.WeightOrigin')} (KGM)`, _style:'min-width: 180px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
      { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'min-width: 200px;', sorter: true, filter: true},
      { key: 'ComputedSerial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'min-width: 160px;', sorter: true, filter: true},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'min-width: 150px;', sorter: true, filter: true},
      { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'min-width: 150px;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
      { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'min-width: 150px;', _classes:"center-cell text-center", sorter: true, filter: true},
      { key: 'ComputedSquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'min-width: 150px;', sorter: true, filter: true},
    ]
  }else{
    return [ 
      { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
      { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
      { key: 'ComputedSerial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
      { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _style:'width:15%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
      { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _style:'width:15%;', _classes:"center-cell text-center", sorter: true, filter: true},
      { key: 'ComputedSquareMeters', label: 'M²',  _classes: 'text-uppercase text-center', _style: 'width:15%;', sorter: true, filter: true},
      { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
    ]
  }
}

function TpCargoId() {
  return this.form.dataBasic.TpCargoId;
}

export default {
  name: 'packaging-tab',
  components: {
      Money
  },
  data,
  props: {
    form: {
      type: Object,
      required: true,
      default: () => {},
    },
    CommodityList: {
      type: Array,
      default: () => [],
    },
    vForm: Object,
    showModal: Boolean,
    validCargoMaster: Boolean,
    Tab: Boolean,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    SubmitPackaging,
    EditPackaging,
    DeletePackaging,
    onChangePackagingOrigin,
    onChangeSerialOrigin,
    onChangeCommodityId,
    onChangePackagingId,
    Clear,
  },
  computed:{
    PackagingOriginOptions,
    SerialOriginOptions,
    CommodityOptions,
    PackagingOptions,
    TotalBl,
    Meters,
    computedListPackaging,
    packagingfields,
    TpCargoId,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.TabIndex = 0;
        this.Clear(true);
      }
    },
    Tab: function (Newval) {
      if (Newval) {
        this.getService();
      }
    },
    TpCargoId: function name(Newval) {
      this.form.Packaging.HeadingId = '';
      this.form.Packaging.CommodityName = '';
    },
  }
};
</script>